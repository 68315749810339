<section class="mx-auto my-28 sm:mt-40 max-w-screen-2xl">
  <div class="w-10/12 mx-auto sm:w-9/12">
    <h2>{{ 'shopguard.inteligent_video.title' | translate }}</h2>
    <p class="mb-12 mt-3">{{'shopguard.inteligent_video.text' | translate}}</p>
    <div class="flex-row-reverse mt-12 lg:flex gap-5">
      <div class="relative lg:w-1/2 2xl:w-1/3 flex items-center mb-5 xl:mb-0">
        <video src="../../../../assets/img/Shopguard/shopguarad.mp4" autoplay loop playsinline alt="Shopguard" class="mx-auto"></video>
      </div>

      <div class="lg:w-1/2 2xl:w-2/3 grid grdi-cols-1 xl:grid-cols-2 gap-9">
        <div class="flex" *ngFor="let item of ['1','2','3','4']">
          <div class="numbers">{{ '0' + item }}</div>
          <div class="vl h-2/3"></div>
          <div class="pl-4">
            <h6>{{ 'shopguard.inteligent_video.title' + item | translate }}</h6>
            <p class="mt-3 text-sm leading-snug" [innerHTML]="'shopguard.inteligent_video.text' + item | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
