import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  interface Window {
    gtag_report_conversion?: (url?: string) => false;
    gtag?: (type: string, name: string, data: unknown) => void;
  }
}

if (environment.production) {
  enableProdMode();
  const cookie = window.document.createElement('script');
  cookie.src = 'https://consent.cookiefirst.com/banner.js';
  cookie.getAttribute('data-cookiefirst-key');
  cookie.setAttribute('data-cookiefirst-key', '29b2cf1b-fd55-464a-a0dd-b1b8756a85f3')
  window.document.body.appendChild(cookie);

  const GTM = window.document.createElement('script');
  GTM.src = 'assets/js/GTM.js';
  window.document.head.appendChild(GTM);

  const fs = window.document.createElement('script');
  fs.src = 'assets/js/fastbase.js';
  window.document.head.appendChild(fs);

  const clearbit = window.document.createElement('script');
  fs.src = 'https://tag.clearbitscripts.com/v1/pk_1f64746428c10fc93d56d4d9076700a6/tags.js';
  fs.async = true;
  fs.referrerPolicy = 'strict-origin-when-cross-origin';
  window.document.head.appendChild(clearbit);

  const reportConversion = window.document.createElement('script');
  reportConversion.src = 'assets/js/report-conversion.js';
  window.document.head.appendChild(reportConversion);
}

function bootstrap() {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
